import '../css/App.css';
import React from 'react';
import Mean from './Mean.js';
import Table from './Table.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class App extends React.Component{
  constructor(props){
    super(props);

    this.calcMean=this.calcMean.bind(this);
    this.findItems=this.findItems.bind(this);
  }

  calcMean(items){
    const arr = items;
    const sum = arr.reduce((a, b) => a + b, 0);
    const avg = (sum / arr.length) || 0;
    return avg;
  }

  findItems(targetMean=100, count=10, lower=90, upper=110) {
    /* create array and fill it with random values */
    let items=[];
    var i;
    for (i=1; i<=count; i++) items.push(Math.floor(Math.random() * (upper - lower + 1) + lower));
    console.log('GENERATED ARRAY');
    console.log(items);
    var mean=this.calcMean(items);
    console.log('MEAN');
    console.log(mean);

    var randomPointer;
    i=0;

    while (mean!==targetMean) {
      randomPointer=Math.floor(Math.random() * items.length);
      if (mean>targetMean) {
        if (items[randomPointer]<=lower) continue;
        items[randomPointer]--;
      }
      if (mean<targetMean) {
        if (items[randomPointer]>=upper) continue;
        items[randomPointer]++;
      }

      i++;
      if (i>=1000) {
        alert(`Too many iterations (limit is ${i}). Try again or reload page`);
        return [];
      }
      mean = this.calcMean(items);
    }
    console.log('IMPROVED:');
    console.log(items);
    return items;
  }

  render(){
    return <React.Fragment>
    <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src="android-chrome-192x192.png" alt="Rybomer" width="40" height="40" className="d-inline-block align-text-top" />
        </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <Link className="nav-link" to="/">Mean</Link>
              <Link className="nav-link" to="/table">Table</Link>
            </div>
          </div>
        </div>
      </nav>

      <div className="container pt-1" id="content-container">
        <Switch>
          <Route path="/table">
            <Table calcMean={this.calcMean} findItems={this.findItems} />
          </Route>
          <Route path="/">
            <Mean calcMean={this.calcMean} findItems={this.findItems} />
          </Route>
        </Switch>
      </div>
    </Router>
    </React.Fragment>;
  }
}

export default App;
